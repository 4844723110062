<template>
  <div
    class="
      absolute
      bottom-11
      right-2
      bg-white bg-opacity-70
      p-1.5
      rounded
      shadow
    "
    @click.stop
  >
    <div v-for="item in menus" :key="item.name">
      <button
        @click="click(item)"
        :disabled="item.disabled"
        :title="item.title"
        class="
          text-sm
          mx-px
          h-7
          leading-7
          flex-none
          rounded
          flex
          w-full
          px-2
          disabled:cursor-not-allowed
          disabled:bg-transparent
          disabled:shadow-none
          hover:bg-white hover:shadow
          focus:bg-white focus:shadow
        "
      >
        <i class="w-4 mr-2 self-center" :class="item.icon"></i>
        <span>{{ item.name }}</span>
      </button>
      <hr v-if="item.divider" class="ml-7 my-1 border-gray-300" />
    </div>
  </div>
</template>

<script>
export default {
  name: "MoreMenu",
  props: {
    previousDisabled: {
      typeof: Boolean,
      default: false,
    },
    nextDisabled: {
      typeof: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menus: [
        {
          icon: "fa fa-chevron-left",
          name: "上一张",
          click: "previous",
          disabled: this.previousDisabled,
          title: "上一张",
        },
        {
          icon: "fa fa-chevron-right",
          name: "下一张",
          click: "next",
          disabled: this.nextDisabled,
          title: "没有更多了",
        },
        {
          icon: "fa fa-picture-o",
          name: "Bing 壁纸",
          divider: true,
          click: "wallpaper",
          title: "Bing 壁纸",
        },
        {
          icon: "fa fa-key",
          name: "密码盒",
          click: "toPasswordBox",
          title: "暂未开发",
          divider: true,
        },
        {
          icon: "fa fa-bookmark",
          name: "书签管理器",
          click: "bookmark",
          disabled: true,
          title: "暂未开发",
        },
        {
          icon: "fa fa-cog",
          name: "设置",
          click: "settings",
          disabled: true,
          title: "暂未开发",
          divider: true,
        },
        {
          icon: "fa fa-sign-out",
          name: "退出",
          click: "logout",
          title: "退出",
        },
      ],
    };
  },
  watch: {
    previousDisabled(newVal) {
      const previous = this.menus[0];
      previous.title = newVal ? "没有更多了" : previous.name;
      previous.disabled = newVal;
    },
    nextDisabled(newVal) {
      const next = this.menus[1];
      next.title = newVal ? "没有更多了" : next.name;
      next.disabled = newVal;
    },
  },
  methods: {
    click(menu) {
      this.$emit(menu.click);
    },
  },
};
</script>