<template>
  <div
    @click.self="closeContextmenu"
    
    v-show="!hidden"
    class="absolute top-0 left-0 w-full h-full z-40"
  >
    <div
      class="
        absolute
        w-56
        py-1
        rounded-md
        shadow-lg
        ring-1 ring-black ring-opacity-5
        bg-white
        text-gray-800
        select-none
      "
      :style="{ left: left + 'px', top: top + 'px' }"
    >
      <div v-for="(item, index) in options" :key="index">
        <button
          @click="contextmenuClick(item, $event)"
          :class="
            item.disabled
              ? 'text-gray-400 hover:text-gray-400 hover:bg-white'
              : 'hover:bg-gray-100 hover:text-black'
          "
          class="
            flex
            w-full
            items-center
            px-3
            py-1.5
            text-sm
            cursor-default
            focus:outline-none
          "
          :disabled="item.disabled"
        >
          <!-- <span class="w-4.5 h-4.5 mr-2 text-lg leading-3">
          <i v-if="item.icon" :class="item.icon"></i>
        </span> -->
          <span class="truncate">{{ item.title }}</span>
        </button>
        <hr v-if="item.divider" class="my-1" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      options: [], //外部给的
      left: 0, //坐标x
      top: 0, //坐标y
      hidden: true, //控制是否显示
    };
  },
  methods: {
    contextmenuClick(option, event) {
      option.click?.(event);
      this.closeContextmenu();
    },
    contextmenu(options, event, onClose) {
      this.options = options;
      this.left = event.clientX;
      this.top = event.clientY;
      this.close = onClose;
      this.hidden = false;
    },
    closeContextmenu() {
      this.hidden = true;
      this.close?.();
    },
  },
};
</script>

<style>
</style>