import Vue from 'vue'
import Vuex from 'vuex'

const STORAGE_VUEX_STATE_KEY = "SESSION_STORAGE_VUEX_STATE";

Vue.use(Vuex);

// iPhone, iPad 不支持 beforeunload 事件, 用 pagehide 代替
const isIOS = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
const event = isIOS ? "pagehide" : "beforeunload";

//添加刷新监听,刷新之前存储vuex-state
window.addEventListener(event, () => {
  localStorage.setItem(
    STORAGE_VUEX_STATE_KEY,
    JSON.stringify(vuex.state)
  );
});

let storageVuexState = JSON.parse(localStorage.getItem(STORAGE_VUEX_STATE_KEY));

let vuex = new Vuex.Store({
  state: storageVuexState ? storageVuexState : {
    user: null
  },
  mutations: {
    login(state, user) {
      state.user = user;
    },
    logout(state) {
      state.user = null;
    }
  },
  actions: {
  },
  modules: {
  }
});

export default vuex;