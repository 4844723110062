<template>
  <div @contextmenu.stop.prevent="rootContextmenu" class="
      bookmark-root
      grid
      gap-y-3
      justify-evenly
      pt-5
      sm:gap-0
      sm:flex
      sm:flex-wrap
      sm:justify-start
      sm:h-auto
      sm:bg-white
      sm:bg-opacity-70
      sm:px-1.5
      sm:pt-1
      sm:shadow
      pb-1
    " style="grid-template-columns: repeat(4, auto)">
    <BookmarkNode @contextmenu="contextmenu" v-for="item in bookmark" :key="item.id" :bookmark="item"></BookmarkNode>
    <BookmarkForm ref="bookmarkForm" @submit="loadBookmark"></BookmarkForm>
  </div>
</template>

<script>
import api from "@/http/api";
import BookmarkNode from "./BookmarkNode";
import BookmarkForm from "./BookmarkForm.vue";

export default {
  components: {
    BookmarkNode, BookmarkForm
  },
  name: "bookmark",
  data: function () {
    return {
      bookmark: [],
      contextVnode: null,
      contextVnodePath: [],
    };
  },
  methods: {
    rootContextmenu(e) {
      this.$contextmenu([{
        title: "添加",
        click: () => {
          this.$refs.bookmarkForm.add({});
        }
      }], e, () => { });
    },
    contextmenu(e) {
      switchOldNewVnodesContextmenuEnabled(this.contextVnodePath, e.vNodePath);
      this.contextVnode = e.vNode;
      this.contextVnodePath = e.vNodePath;
      this.$contextmenu(
        this.createContextmenuOption(e.vNode.bookmark),
        e,
        this.contextmenuOnClose
      );
    },
    createContextmenuOption(bookmark) {
      return [
        {
          title: "打开",
          disabled: !bookmark.u,
          click: () => {
            window.location.href = bookmark.u;
          },
        },
        {
          title: "在新标签页打开",
          disabled: !bookmark.u && !bookmark.c,
          divider: true,
          click: () => {
            if (bookmark.c) {
              bookmark.c.forEach((element) => {
                window.open(element.u);
              });
            } else {
              window.open(bookmark.u);
            }
          },
        },
        {
          title: "复制链接",
          disabled: !bookmark.u,
          click: () => {
            this.$copy(bookmark.u);
          },
        },
        {
          title: "复制为 Markdown 链接",
          disabled: !bookmark.u,
          divider: true,
          click: () => {
            this.$copy(
              "[" + bookmark.n + "](" + (bookmark.u ? bookmark.u : "") + ")"
            );
          },
        },
        {
          title: "书签管理",
          divider: true,
        },
        {
          disabled: bookmark.u,
          title: `在 ${bookmark.n} 下添加`,
          click: () => {
            this.$refs.bookmarkForm.add(bookmark);
          }
        },
        {
          title: "编辑",
          click: () => {
            this.$refs.bookmarkForm.edit(bookmark);
          }
        },
        {
          title: "删除",
          click: () => {
            this.deleteById(bookmark.i);
          }
        },
      ];
    },
    contextmenuOnClose() {
      switchVnodesContext(this.contextVnodePath, false);
    },
    deleteById(bookmarkId) {
      api.deleteBookmarkById([bookmarkId]).then(() => {
        this.loadBookmark();
      });
    },
    loadBookmark() {
      api.getBookmark().then((r) => {
        this.bookmark = r.data;
      });
    }
  },

  created() {
    this.loadBookmark();
  },
};

/**
 * 折叠旧的展开节点, 展开新节点
 * 优化: 新/旧节点集合 中相同节点, 只改变状态一次
 */
function switchOldNewVnodesContextmenuEnabled(oldVnodes, newVnodes) {
  switchVnodesContext(oldVnodes, false);
  switchVnodesContext(newVnodes, true);
}

/**
 * 改变节点 展开状态
 */
function switchVnodesContext(vNodes, state) {
  for (const vNode of vNodes) {
    vNode.contextmenuEnabled = state;
  }
}
</script>