<template>
  <div @contextmenu.stop.prevent="contextmenu" :class="{ 'bg-white shadow': contextmenuEnabled }" class="
      bookmark-node
      text-xs 
      w-auto
      mx-px
      h-7
      leading-7
      text-black
      mt-0
      hover:bg-white
      hover:shadow
      transition-colors
      flex-none
      rounded
    ">
    <bookmark-button :bookmark="bookmark"></bookmark-button>
    <div v-if="bookmark.c" :class="{ invisible: !contextmenuEnabled }" class="child-folder absolute pt-2">
      <div class="child-container p-2 bg-white overflow-auto bg-opacity-70 shadow-md rounded"
           style="max-height: calc(100vh - 5rem);">
        <bookmarkNode class="child-node" v-for="item in bookmark.c" :key="item.n" :bookmark="item"
                      @contextmenu="contextmenu"></bookmarkNode>
      </div>
    </div>
  </div>
</template>

<script>
import BookmarkButton from "./BookmarkButton.vue";

export default {
  name: "BookmarkNode",
  components: {
    BookmarkButton
  },
  props: {
    bookmark: Object,
  },
  data() {
    return {
      contextmenuEnabled: false,
    };
  },
  methods: {
    contextmenu(e) {
      if (!e.vNodePath) {
        e.vNodePath = [];
        e.vNode = this;
      }
      e.vNodePath.push(this);
      this.$emit("contextmenu", e);
    },
  },
};
</script>
<style scoped>
/* 一层 hover 子层显示 */
.bookmark-node:hover > .child-folder {
  visibility: visible;
  z-index: 10;
}

/* 延迟隐藏 */
.bookmark-node > .child-folder {
  transition: visibility .1s;
}

.bookmark-node:hover > .folder-icon {
  @apply opacity-60
}

.child-folder .child-folder {
  @apply left-full -mt-11 -ml-2 pl-3;
}
</style>
<style>
/* 根元素不需要延迟隐藏 */
.bookmark-root > .bookmark-node > .child-folder {
  transition: none;
}
</style>