import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import Store from "@/store/index"

import Home from '@/views/Home'
import Login from "@/views/Login"
import Wallpaper from "@/views/Wallpaper"
import Test from '@/views/Test'
import NotFound from '@/views/NotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录'
    }
  }, {
    path: '/wallpaper',
    name: 'wallpaper',
    component: Wallpaper,
    meta: {
      title: 'Bing 壁纸'
    }
  }, {
    path: '/test',
    name: 'Test',
    component: Test
  }, {
    path: '*',
    name: '404',
    component: NotFound,
    meta: {
      title: "404 Not Found"
    }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  NProgress.start();
  console.log("router:", to.name);
  if (to.name != 'Login' && !Store.state.user) {
    next({ name: 'Login' });
  } else if (to.name == 'Login' && Store.state.user) {
    NProgress.done();
    next({ name: 'Home' });
  } else {
    next();
  }
})

router.afterEach((to) => {
  window.document.title = (to.meta.title ? to.meta.title + " - " : "") + "书签栏";
  NProgress.done();
})

export default router
