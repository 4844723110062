<template>
  <div class="w-full h-full bg-black bg-opacity-20">
    <div
      class="w-full h-full absolute -z-10 bg-center bg-cover"
      style="filter: blur(7px)"
      ref="background"
    ></div>
    <div
      class="w-full h-full flex overflow-auto flex-wrap justify-evenly pb-10"
      ref="wallpaperDiv"
    >
      <wallpaper-item
        v-for="wallpaper in wallpapers"
        :key="wallpaper.imgUrl"
        :wallpaper="wallpaper"
        @click="openBig(wallpaper)"
      >
      </wallpaper-item>
      <div class="w-full text-center text-white text-opacity-60" ref="loadDiv">
        {{ load }}
      </div>
    </div>
    <transition name="fade">
      <button
        class="
          absolute
          bottom-2
          right-29
          px-2
          py-1
          bg-white bg-opacity-40
          rounded
          hover:bg-opacity-70
          transition
          shadow-md
        "
        title="回到顶部"
        @click="topClick"
        v-show="topShow"
      >
        <i class="fa fa-arrow-up" />
      </button>
    </transition>
    <router-link
      class="
        absolute
        bottom-2
        right-2
        px-2
        py-1
        bg-white bg-opacity-40
        rounded
        hover:bg-opacity-70
        transition
        shadow-md
      "
      to="/"
    >
      <i class="fa fa-home" />
      返回主页
    </router-link>
    <transition name="fade">
      <wallpaper-big
        v-show="bigShow"
        :wallpaper="wallpaper"
        @close="bigShow = false"
      ></wallpaper-big>
    </transition>
  </div>
</template>

<script>
import api from "@/http/api.js";
import WallpaperItem from "../components/wallpaper/WallpaperItem.vue";
import WallpaperBig from "../components/wallpaper/WallpaperBig.vue";

export default {
  components: {
    WallpaperItem,
    WallpaperBig,
  },
  data() {
    return {
      wallpapers: [],
      total: 0,
      page: 1,
      pageSize: this.$route.query.pageSize ? this.$route.query.pageSize : 20,
      topShow: false,
      wallpaper: {},
      bigShow: false,
      timer: null,
    };
  },
  created() {
    this.requestData(this.setBackground);
  },
  mounted() {
    this.addScrollListener();
  },
  beforeDestroy() {
    this.removeScrollListener();
  },
  methods: {
    requestData(callback) {
      api.getBackground(this.page, this.pageSize).then((r) => {
        this.wallpapers = this.wallpapers.concat(r.data);
        this.total = r.total;
        this.page++;
        if (callback) {
          callback();
        }
      });
    },
    /**
     * 设置背景
     */
    setBackground() {
      const routerUrl = this.$route.params.background;
      const url =
        'url("' + (routerUrl ? routerUrl : this.wallpapers[0].imgUrl) + '")';
      document.body.style.backgroundImage = url;
      this.$refs.background.style.backgroundImage = url;
    },
    /**
     * 回到顶部 按钮点击
     */
    topClick() {
      this.$refs.wallpaperDiv.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    /**
     * 添加滚动监听
     */
    addScrollListener() {
      this.$refs.wallpaperDiv.addEventListener("scroll", this.scrollListener);
    },
    /**
     * 移除滚动监听
     */
    removeScrollListener() {
      this.$refs.wallpaperDiv.removeEventListener(
        "scroll",
        this.scrollListener
      );
    },
    /**
     * 滚动监听
     */
    scrollListener() {
      // 当滚动距离大于 可视高度的二分之一时, 显示 回到顶部 按钮
      const wallpaperDiv = this.$refs.wallpaperDiv;
      if (wallpaperDiv.scrollTop > document.body.clientHeight / 2) {
        this.topShow = true;
      } else {
        this.topShow = false;
      }

      // loadDiv显示时, 加载下一页
      if (
        wallpaperDiv.scrollTop + wallpaperDiv.clientHeight >
        this.$refs.loadDiv.offsetTop
      ) {
        this.shakeRequestData();
      }
    },
    /**
     * 消抖请求数据
     */
    shakeRequestData() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(this.requestData, 200);
    },
    /**
     * 小图点击显示大图
     */
    openBig(wallpaper) {
      this.wallpaper = wallpaper;
      this.bigShow = true;
    },
  },
  computed: {
    clickWallpaper() {
      let clickWallpaper = this.wallpapers[this.clickIndex];
      return clickWallpaper ? clickWallpaper : {};
    },
    load() {
      if (this.wallpapers.length == this.total) {
        return "没有更多了... 共计 " + this.total + " 张";
      } else {
        return "正在加载...";
      }
    },
  },
};
</script>