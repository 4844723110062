<template>
  <div class="h-full w-full">
    <Background></Background>
    <Bookmark></Bookmark>
  </div>
</template>

<script>
import Background from "@/components/background/background.vue";
import Bookmark from "@/components/bookmark/Bookmark.vue";

export default {
  name: "Home",
  components: {
    Background,
    Bookmark,
  },
  data: function () {
    return {};
  },
  created() {},
};
</script>