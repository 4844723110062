<template>
  <div class="w-full h-full flex items-center justify-center">
    <div class="shadow rounded border p-4 flex">
      <div class="" style="width: 160px">
        <div>书签管理</div>
      </div>
      <div style="width: 640px">

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Test",
  components: {},
  data() {
    return {
    };
  },
  methods: {

  },
};
</script>