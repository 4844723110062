<template>
  <div
    class="
      wallpaper-item
      w-1/5
      m-10
      transition-transform
      transform
      select-none
      hover:scale-125
      group
      cursor-pointer
      shadow-md
    "
    @click="$emit('click')"
  >
    <img
      class="rounded-t"
      :src="wallpaper.imgUrl"
      :alt="wallpaper.description"
      draggable="false"
      :title="wallpaper.description"
    />
    <div
      class="
        flex
        justify-between
        bg-white bg-opacity-10
        px-2
        py-1
        rounded-b
        text-gray-100 text-opacity-90
        group-hover:text-gray-100 group-hover:bg-opacity-20
      "
    >
      <a
        class="truncate hover:underline"
        :href="wallpaper.searchUrl"
        :title="wallpaper.description"
        @click.stop
      >
        {{ wallpaper.description }}
      </a>
      <span class="ml-1 flex-shrink-0" :title="cdateTitle">
        <i class="fa fa-calendar" />
        {{ cdate }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    wallpaper: {
      typeof: Object,
      default: {
        searchUrl: "#",
        cdate: new Date(),
        description: "",
        imgUrl: "#",
      },
    },
  },
  computed: {
    cdate() {
      return this.$moment(this.wallpaper.cdate).format("MM-DD");
    },
    cdateTitle() {
      return this.$moment(this.wallpaper.cdate).format("YYYY年MM月DD日");
    },
  },
};
</script>