<template>
  <div class="background" v-if="background[enableIndex]">
    <transition name="fade">
      <img
        v-show="show"
        :src="background[enableIndex].imgUrl"
        @load="imgOnLoad"
        class="absolute w-full h-full object-cover object-center -z-10"
      />
    </transition>
    <description
      :url="background[enableIndex].searchUrl"
      :title="background[enableIndex].description"
      class="tool-button bg-white"
    ></description>
    <more-button
      @click="moreClick"
      class="tool-button bg-white"
      :class="
        moreShow ? 'text-black bg-opacity-70' : 'text-gray-700 bg-opacity-40'
      "
    ></more-button>
    <more-menu
      v-show="moreShow"
      @previous="previousClick"
      :previousDisabled="enableIndex == total - 1"
      @next="nextClick"
      :nextDisabled="enableIndex == 0"
      @wallpaper="toWallpaper"
      @logout="logout"
      @toPasswordBox="toPasswordBox"
    ></more-menu>
  </div>
</template>

<script>
import api from "@/http/api.js";
import Description from "./Description";
import MoreButton from "./MoreButton.vue";
import MoreMenu from "./MoreMenu";

export default {
  name: "background",
  components: {
    MoreMenu,
    Description,
    MoreButton,
  },
  data: function () {
    return {
      background: [],
      show: false,
      enableIndex: 0,
      total: 0,
      page: 1,
      moreShow: false,
    };
  },
  created() {
    this.requestData();
  },
  methods: {
    /**
     * 请求壁纸数据
     */
    requestData(callback) {
      api.getBackground(this.page).then((r) => {
        this.background = this.background.concat(r.data);
        this.total = r.total;
        this.page++;
        if (callback) {
          callback();
        }
      });
    },
    /**
     * 图片加载完事件
     */
    imgOnLoad() {
      this.show = true;
    },
    /**
     * 上一个按钮点击事件
     */
    previousClick() {
      this.setBodyBackground();
      let previousEnableIndex = this.enableIndex + 1;
      let previousBackground = this.background[previousEnableIndex];
      if (previousBackground) {
        this.loadImg(previousBackground.imgUrl, () => {
          this.enableIndex = previousEnableIndex;
        });
      } else if (this.total > this.background.length) {
        this.requestData(() => {
          this.previousClick();
        });
      }
    },
    /**
     * 下一个按钮点击事件
     */
    nextClick() {
      this.setBodyBackground();
      let nextEnableIndex = this.enableIndex - 1;
      let nextBackground = this.background[nextEnableIndex];
      if (nextBackground) {
        this.loadImg(nextBackground.imgUrl, () => {
          this.enableIndex = nextEnableIndex;
        });
      }
    },
    /**
     * 加载img
     */
    loadImg(url, callback) {
      this.show = false;
      let startTime = new Date();
      let img = new Image();
      img.onload = () => {
        let endTime = new Date() - startTime;
        if (endTime < 300) {
          setTimeout(() => {
            callback();
          }, 300 - endTime);
        } else {
          callback();
        }
      };
      img.src = url;
    },
    /**
     * 设置body的背景为 enableIndex
     */
    setBodyBackground() {
      document.body.style.backgroundImage =
        'url("' + this.background[this.enableIndex].imgUrl + '")';
    },
    /**
     * 更多点击事件
     */
    moreClick() {
      if (this.moreShow) {
        this.moreCloseListener();
      } else {
        window.addEventListener("click", this.moreCloseListener);
        this.moreShow = true;
      }
    },
    /**
     * 更多菜单关闭监听
     */
    moreCloseListener() {
      window.removeEventListener("click", this.moreCloseListener);
      this.moreShow = false;
    },
    /**
     * 跳转到壁纸页面
     */
    toWallpaper() {
      this.setBodyBackground();
      this.$router.push({
        name: "wallpaper",
        params: { background: this.background[this.enableIndex].imgUrl },
      });
    },
    /**
     * 退出
     */
    logout() {
      api.logout().then(() => {
        this.$store.commit("logout");
        this.$router.push("/login");
      });
    },
    /**
     * 密码盒
     */
    toPasswordBox(){
      window.location = 'http://pwd.glh.red'
    }
  },
};
</script>
<style scoped>
.tool-button {
  @apply fixed
      inline-block
      bottom-2
      h-7
      text-xs text-center
      leading-7
      rounded
      border-0
      no-underline
      transition
      shadow
      hover:text-black hover:bg-opacity-70;
}
</style>