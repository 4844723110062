<template>
  <a :href="bookmark.u ? bookmark.u : '#'" :title="title" class="
        flex flex-row
        items-center
        rounded w-full px-1.5
        no-underline
      ">
    <img :src="imgSrc" @error="iconError" class="w-4.5 h-4.5 mr-2 rounded" :alt="bookmark.n" />
    <div>
      <span class="truncate max-w-xs block">{{ bookmark.n }}</span>
    </div>
  </a>
</template>
<script>
import earth from "@/assets/img/earth.svg";
import folderCloset from "@/assets/img/folder_closet.svg";

export default {
  name: 'BookmarkButton',
  props: {
    bookmark: Object
  },
  methods: {
    iconError(e) {
      e.srcElement.src = earth;
    }
  },
  computed: {
    /**
     * title属性
     */
    title() {
      const bookmark = this.bookmark;
      return `${bookmark.n}\n${bookmark.u ? bookmark.u : ''}`
    },
    /**
     * 图标src
     **/
    imgSrc() {
      // return earth;
      const bookmark = this.bookmark;
      if (bookmark.o && bookmark.o != "/imgs/folder_closet.svg") {
        // 判断是否设置了, 优先使用设置的
        return bookmark.o;
      } else {
        if (bookmark.c) {
          // 不是链接,返回文件夹图标
          return folderCloset;
        } else if (bookmark.u) {
          // 判断是否是链接, 是连接, 拼接图标路径
          return "http://" + bookmark.u.split("/")[2] + "/favicon.ico";
        }
        return "/imgs/folder_closet.svg";
      }
    },
  },
}
</script>
<style scoped>
/* 子层 */
.child-node {
  @apply m-0 rounded transition-none;
}

/* 子层a */
.child-node a {
  @apply rounded-none truncate pr-6 pl-2;
}

/* 子层图标 */
.child-node a img {
  @apply w-4 h-4 align-text-top mr-3;
}
</style>
