<template>
  <div v-if="show" class="bookmark-form" @contextmenu.stop>
    <div class="bookmark-form-title">
      {{ title }}
    </div>
    <div>
      <div class="bookmark-form-item flex mb-4">
        <label>
          类型
        </label>
        <div class="relative border rounded hover:shadow-md transition-all">
          <button class="px-1 py-1.5 w-20 relative z-10 transition-colors" :class="{ 'text-white': type == 'link' }"
            @click="type = 'link'">
            链接
          </button>
          <button class="px-1 py-1.5 w-20 relative z-10 transition-colors" :class="{ 'text-white': type == 'dir' }"
            @click="type = 'dir'">
            文件夹
          </button>
          <div class="w-20 h-full rounded absolute top-0 z-0 bg-green-400 transition-all"
            :class="type == 'dir' ? 'left-20' : 'left-0'">

          </div>
        </div>
      </div>
      <div class="bookmark-form-item transition-all" :class="type == 'link' ? 'h-10 mb-4' : 'h-0 mb-0 overflow-hidden'">
        <label>地址</label>
        <input v-model="form.url" style="width: 16.5rem" />
        <button
          class="border w-12 rounded ml-2 hover:border-green-400 hover:text-green-400 hover:shadow-md transition-all"
          @click="parse" :disabled="parseLoadding"><i class="fa fa-bolt" /></button>
      </div>
      <div class="bookmark-form-item mb-4">
        <label>名称</label>
        <input v-model="form.name" />
      </div>
      <div class="bookmark-form-item mb-4">
        <label>图标</label>
        <input v-model="form.icon" style="width: 17rem" />
        <div class="ml-2">
          <img class="w-10 h-10" :src="imgIconUrl" @error="imgError" />
        </div>
      </div>
      <div class="bookmark-form-item mb-4">
        <label>序号</label>
        <input v-model="form.idx" type="number" />
      </div>
      <div class="text-right">
        <button class="bookmark-form-button bg-green-400 hover:bg-green-500 text-white mr-2" @click="save"
          :disabled="saveing">保存</button>
        <button class="bookmark-form-button text-gray-800 hover:bg-gray-200" @click="show = false">关闭</button>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/http/http.js";

export default {
  name: "bookmark",
  data: function () {
    return {
      model: 'add',
      form: this.formDefault(),
      saveing: false,
      show: false,
      type: 'dir',
      parseLoadding: false,
      imgIconUrl: '/img/earth.82b32214.svg',
      imgTimer: undefined
    };
  },
  computed: {
    title() {
      return {
        'add': '添加书签',
        'edit': '编辑书签'
      }[this.model]
    }
  },
  methods: {
    add(bookmark) {
      this.open('add', {
        pid: bookmark.i
      })
    },
    edit(initForm) {
      this.open('edit', {
        id: initForm.i,
        name: initForm.n,
        icon: initForm.o,
        url: initForm.u,
        idx: initForm.d,
        pid: initForm.p,
      });
    },
    open(model, initForm) {
      this.model = model;
      this.form = Object.assign(this.formDefault(), initForm);
      this.type = this.form.url ? 'link' : 'dir'
      this.show = true;
    },
    formDefault() {
      return {
        name: '',
        icon: '',
        url: ''
      }
    },
    /**
     * 表单验证
     * @returns true 验证成功, false 验证失败
     */
    checkForm() {
      for (const item of ['name']) {
        if (!this.form[item]) {
          const nameMapping = {
            name: "名称",
            icon: '图标',
            url: "地址"
          }
          this.$notice(`请填写 ${nameMapping[item]}`, 'error');
          return false;
        }
      }
      return true;
    },
    save() {
      this.saveing = true;
      if (!this.checkForm()) {
        this.saveing = false;
        return;
      }
      const url = {
        'add': '/bookmark/add',
        'edit': '/bookmark/edit'
      }[this.model]
      if (this.type == 'dir') {
        this.form.url = null;
      }
      api.post(url, this.form).then(() => {
        this.show = false;
        this.$emit('submit');
      }).finally(() => {
        this.saveing = false;
      })
    },
    /**
     * url 解析
     */
    parse() {
      if (!this.form.url) {
        return;
      }
      this.parseLoadding = true;
      api.get('/bookmark/parse', { url: this.form.url }).then(r => {
        this.form.name = r.data.title;
        this.form.icon = r.data.icon;
      }).catch(r => {
        console.log(r)
      }).finally(() => {
        this.parseLoadding = false;
      })
    },
    /**
     * 图标加载失败
     */
    imgError() {
      this.imgIconUrl = '/img/earth.82b32214.svg'
    }
  },
  watch: {
    'form.icon'(newVal) {
      clearTimeout(this.imgTimer);
      this.imgTimer = setTimeout(() => {
        this.imgIconUrl = newVal ? newVal : '/img/earth.82b32214.svg';
      }, 300);
    }
  }
}
</script>
<style scoped>
.bookmark-form {
  @apply absolute border bg-white rounded p-8 shadow top-1/4 left-1/2;
  transform: translate(-50%)
}

.bookmark-form-title {
  @apply mb-6;
}

.bookmark-form-item {
  @apply flex text-sm
}

.bookmark-form-item>label {
  @apply flex-none w-10 text-right pr-3 py-1.5;
}

.bookmark-form-item>input {
  @apply border outline-none bg-transparent h-10 px-3 py-1.5 placeholder-gray-500 tracking-wide ring-green-300 rounded hover:shadow-md focus:outline-none focus:ring focus:shadow-md focus:placeholder-gray-400 transition-all;
  width: 20rem;
}

.bookmark-form-button {
  @apply px-4 py-1.5 tracking-widest transition-all shadow hover:shadow-md focus:outline-none disabled:opacity-90 rounded;
}
</style>