export default {
    install: (Vue) => {
        Vue.prototype.$copy = copy;
    }
}


const COPY_Input_ID = "COPY_INPUT";

function copy(text) {
    let copyInput = document.getElementById(COPY_Input_ID);
    if (!copyInput) {
        copyInput = createCopyInput(text);
    } else {
        copyInput.style.display = "inline-block";
        copyInput.value = text;
    }
    copyInput.select();
    document.execCommand("copy");
    copyInput.style.display = "none";
}

function createCopyInput(text) {
    const copyInput = document.createElement("input");
    copyInput.setAttribute('id', COPY_Input_ID);
    copyInput.value = text ? text : '';
    copyInput.setAttribute("style", "opacity: 0; position: absolute; left: 0; bottom: 0; cursor: default;");
    document.body.appendChild(copyInput);
    return copyInput;
}