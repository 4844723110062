<template>
  <div class="absolute right-3 top-0 w-72 max-h-full overflow-auto">
    <transition name="fade" v-for="(item, index) in notices" :key="index">
      <div
        @mouseenter="mouseenter(index)"
        @mouseleave="mouseleave(index)"
        class="
          w-full
          flex
          px-3
          py-2
          my-3
          text-black
          rounded
          transition-all
          shadow
          hover:shadow-md
        "
        :class="{
          'bg-green-200': item.type == 'info',
          ' bg-yellow-200': item.type == 'warn',
          'bg-red-300': item.type == 'error',
        }"
      >
        <span class="flex-1 cursor-default overflow-ellipsis">{{
          item.msg
        }}</span>
        <button
          v-if="item.close"
          @click="removeByIndex(index)"
          class="focus:outline-none pl-3"
          title="关闭"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Notice",
  data() {
    return {
      notices: [],
    };
  },
  methods: {
    addNotice(msg = "", type = "info", time = 5000, close = true) {
      let timer;
      if (time) {
        timer = setTimeout(() => {
          this.removeByTimer(timer);
        }, time);
      }
      this.notices.unshift({
        msg,
        type,
        time,
        close,
        timer,
      });
    },
    removeByIndex(index, needClearTimer = true) {
      const item = this.notices.splice(index, 1)[0];
      if (needClearTimer && item && item.timer) {
        clearTimeout(item.timer);
      }
    },
    removeByTimer(timer) {
      for (let index = 0; index < this.notices.length; index++) {
        const item = this.notices[index];
        if (item.timer == timer) {
          this.removeByIndex(index, false);
          return;
        }
      }
    },
    mouseenter(index) {
      const item = this.notices[index];
      if (item && item.timer) {
        clearTimeout(item.timer);
      }
    },
    mouseleave(index) {
      const item = this.notices[index];
      if (item && item.time) {
        item.timer = setTimeout(() => {
          this.removeByTimer(item.timer);
        }, item.time);
      }
    },
  },
};
</script>