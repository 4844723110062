import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import moment from "moment";

import "font-awesome/css/font-awesome.min.css";
import 'nprogress/nprogress.css'
import "tailwindcss/tailwind.css"
import "animate.css"


import Notice from "@/components/notice/Notice.js"
import Contextmenu from "@/components/contextmenu/Contextmenu.js"
import Copy from "@/components/copy/Copy.js"

Vue.config.productionTip = false

moment.locale('zh-cn');
Vue.prototype.$moment = moment;

Vue.use(Notice);
Vue.use(Contextmenu);
Vue.use(Copy);

const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vm
