import http from "./http";

const api = {
  login(username, password, remberme) {
    return http.formPost("/login", {
      username,
      password,
      "remember-me": remberme,
    });
  },
  logout() {
    return http.post("/logout");
  },
  getBookmark() {
    return http("/bookmark");
  },
  deleteBookmarkById(bookmarkIds) {
    return http.post("/bookmark/delete", bookmarkIds);
  },
  getBackground(page, size = 5) {
    return http.get("/wallpaper", { pageNumber: page, pageSize: size });
  },
  updateLikeImg(imgId, status) {
    return http.formPost("/background/like", { id: imgId, status: status });
  },
};

export default api;
