<template>
  <a
    class="px-2.5 right-11"
    :href="url"
    :title="title"
    style="max-width: calc(100% - 52px)"
    :class="highlight ? 'bg-opacity-70' : 'bg-opacity-40'"
  >
    <span class="inline-block w-full truncate">{{ title }}</span>
  </a>
</template>

<script>
export default {
  props: {
    url: String,
    title: String,
    // url 改变高亮
    changeHighlight: {
      typeof: Boolean,
      default: true,
    },
    // 高亮时长
    changeHighlightTime: {
      typeof: Number,
      default: 5000,
    },
  },
  data() {
    return {
      timer: null,
      highlight: false,
    };
  },
  watch: {
    url() {
      if (this.changeHighlight) {
        this.highlight = true;
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.highlight = false;
        }, this.changeHighlightTime);
      }
    },
  },
};
</script>