<template>
  <div
    class="
      absolute
      top-0
      w-full
      h-full
      flex
      justify-center
      bg-black bg-opacity-70
    "
    @click.self="close"
  >
    <div class="w-4/6 self-center relative p-4 rounded bg-white shadow-md">
      <button
        @click="close"
        class="
          absolute
          -right-12
          -top-1
          text-4xl text-red-500
          focus:outline-none focus:text-red-900
          hover:text-red-700
          transition
        "
        title="关闭"
      >
        <i class="fa fa-close" />
      </button>
      <img
        :src="wallpaper.imgUrl"
        :alt="wallpaper.description"
        draggable="false"
        :title="wallpaper.description"
        class="rounded"
      />
      <div class="flex justify-between px-1 pt-3.5">
        <a
          class="break-all hover:underline"
          :href="wallpaper.searchUrl"
          :title="wallpaper.description"
        >
          {{ wallpaper.description }}
        </a>
        <span class="ml-2 cursor-default flex-shrink-0" :title="cdateTitle">
          <i class="fa fa-calendar" />
          {{ cdate }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    wallpaper: {
      typeof: Object,
      default() {
        return {
          searchUrl: "#",
          cdate: new Date(),
          description: "",
          imgUrl: "#",
        };
      },
    },
  },
  computed: {
    cdate() {
      return this.$moment(this.wallpaper.cdate).format("YYYY-MM-DD");
    },
    cdateTitle() {
      return this.$moment(this.wallpaper.cdate).format("YYYY年MM月DD日");
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
