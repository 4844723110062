<template>
  <div class="flex items-center justify-center w-full h-full flex-col text-gray-500">
    <h1 class="text-4xl">
      404 Not Found
    </h1>
    <div class="text-gray-600 mt-5">
      <button @click="back" class="mr-2">前页</button>
      <router-link :to="{ path: '/' }">首页</router-link>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    back() {
      this.$router.back();
    }
  }
}
</script>

<style>

</style>