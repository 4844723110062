import axios from "axios";
import qs from "qs";
import NProgress from "nprogress";
import router from "@/router/";
import vm from "@/main.js";
import store from "@/store/";

//请求携带cookie
axios.defaults.withCredentials = true;

//请求拦截器
axios.interceptors.request.use(
  (config) => {
    config.url = "/api" + config.url;
    NProgress.start();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截器
axios.interceptors.response.use(
  (response) => {
    NProgress.done();
    // 判断成功
    if (response.data.success) {
      return response.data;
    }
    return Promise.reject(response.data);
  },
  (error) => {
    NProgress.done();
    switch (error.response.status) {
      case 403:
        vm.$notice("会话失效,正在跳转到登录页面 (403)", "warn");
        store.commit("logout");
        router.push("/login");
        break;
      case 404:
        vm.$notice("未找到请求资源 (404)", "error");
        break;
      case 500:
        vm.$notice("服务器内部错误,请稍后重试 (500)", "error");
        break;
      case 502:
        vm.$notice("服务器连接失败,请稍后重试 (502)", "error");
        break;
      case 504:
        vm.$notice("服务器处理超时,请稍后重试 (504)", "error");
        break;
      default:
        vm.$notice("未知错误,请稍后重试. (" + error.message + ")", "error");
        break;
    }
    return Promise.reject(error);
  }
);

// application/x-www-form-urlencoded 格式POST请求
axios.formPost = (url, data) => {
  return axios({
    method: "POST",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: qs.stringify(data),
    url,
  });
};

// 重写get
axios.get = (url, params) => {
  return axios({
    method: "GET",
    url,
    params: params,
  });
};

export default axios;
