<template>
  <div
    class="flex flex-col justify-center items-center w-full h-full bg-gray-100"
  >
    <div
      :class="{
        'h-12': errorIsShow,
        'h-0': !errorIsShow,
        'animate__animated animate__shakeX animate__faster': errorAnimation,
      }"
      class="
        w-88 sm:w-96
        px-10
        bg-red-400
        leading-12
        text-white
        rounded-lg
        shadow-md
        cursor-default
        transition-all
        overflow-hidden
      "
    >
      <i class="fa fa-exclamation-triangle mr-3"></i>{{ errorMsg }}
    </div>
    <div
      ref="shadow-container"
      class="w-88 sm:w-96 m-3 p-10 cursor-default bg-white rounded-lg shadow-md"
    >
      <div class="border-b border-gray-300 pb-1">
        <h1 class="text-2xl text-gray-700">
          书签栏<span class="ml-4 text-sm text-gray-600">登录</span>
        </h1>
      </div>
      <div class="mt-5">
        <input
          class="input"
          v-model="username"
          id="username"
          placeholder="用户名"
        />
      </div>
      <div class="mt-3">
        <input
          class="input"
          v-model="password"
          id="password"
          placeholder="密码"
          type="password"
          @keyup.enter="loginButtonClick"
        />
      </div>
      <div class="mt-3 flex flex-row-reverse">
        <input
          class="remberme hidden"
          v-model="remberme"
          id="remberme"
          type="checkbox"
        />
        <label
          class="
            inline-block
            p-2
            text-sm text-gray-700
            cursor-pointer
            tracking-wider
            select-none
          "
          for="remberme"
          >记住我</label
        >
      </div>
      <div class="mt-3">
        <button
          :disabled="loginButtonLoad"
          @click="loginButtonClick"
          class="button"
        >
          <i
            v-show="loginButtonLoad"
            class="fa fa-spinner fa-spin absolute mt-1 -ml-5"
          ></i>
          登录
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/http/api.js";
import PasswordUtil from "../util/PasswordUtil";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      remberme: true,
      errorMsg: "",
      errorIsShow: false,
      errorAnimation: false,
      loginButtonLoad: false,
    };
  },
  methods: {
    loginButtonClick() {
      if (!this.verify()) {
        return;
      }
      this.loginButtonLoad = true;
      api
        .login(this.username,PasswordUtil.encode(this.password), this.remberme)
        .then((r) => {
          if (!r.success) {
            this.showError(r.message);
            return;
          }
          this.$store.commit("login", {
            username: this.username,
          });
          this.$router.push("/");
        })
        .finally(() => {
          this.loginButtonLoad = false;
        });
    },
    showError(msg) {
      if (this.errorIsShow) {
        this.errorAnimation = true;
        setTimeout(() => {
          this.errorAnimation = false;
        }, 500);
      }
      this.errorIsShow = true;
      this.errorMsg = msg;
    },
    clearError() {
      this.errorAnimation = false;
      this.errorIsShow = false;
      this.errorMsg = "";
    },
    verify() {
      let un = this.username;
      let pd = this.password;
      if (!un) {
        this.showError("请填写用户名");
        return false;
      }
      if (!pd) {
        this.showError("请填写密码");
        return false;
      }
      if (pd.length < 3) {
        this.showError("密码长度应大于3位");
        return false;
      }
      this.clearError();
      return true;
    },
  },
};
</script>

<style>
.input {
  @apply outline-none
  bg-transparent
  w-full
  h-10
  mt-2
  px-3 py-1.5 
  text-sm
  border-b
  placeholder-gray-500 
  tracking-wide
  ring-green-300
  rounded
  hover:shadow-md
  focus:outline-none focus:ring focus:shadow-md focus:placeholder-gray-400
  transition-all;
}

.button {
  @apply bg-green-400 
  w-full
  px-10 
  py-2 
  text-white 
  tracking-widest	
  transition-all 
  shadow 
  hover:shadow-md 
  hover:bg-green-500 
  focus:outline-none
  disabled:opacity-90
  rounded-lg;
}

.remberme + label::after {
  content: "";
  @apply block w-1 h-2 bg-green-200 -mt-2.5 transition-all rounded;
}

.remberme + label:hover::after {
  content: "";
  @apply block w-full h-2 bg-green-200 -mt-2.5 shadow-md;
}

.remberme:checked + label::after {
  content: "";
  @apply block w-full h-2 bg-green-400 -mt-2.5;
}
</style>